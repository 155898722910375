import { Routes, Route, Navigate } from 'react-router-dom'
import { ConfigProvider } from './contexts/ConfigContext'
import { AuthProvider } from './contexts/AuthContext'
import ProtectedRoute from './components/ProtectedRoute'
import Layout from './pages/Layout'
import Home from './pages/Home'
import Issuers from './pages/Issuers'
import Vendors from './pages/Vendors'
import Faq from './pages/Faq'
import Contact from './pages/Contact'
import ContactConfirm from './pages/ContactConfirm'
import Dashboard from './pages/Dashboard'
import AdminPanel from './components/admin/AdminPanel'
import UsersList from './components/admin/users/UsersList'
import VendorsList from './components/admin/vendors/VendorsList'
import ReportingUnitsList from './components/admin/reporting-units/ReportingUnitsList'
import NotFound from './pages/NotFound'
import Login from './pages/LoginIDM'
import Logout from './pages/Logout'
import Test from './pages/Test'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/app.scss'

const App = () => {
  return (
    <ConfigProvider>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="issuers" element={<Issuers />} />
            <Route path="vendors" element={<Vendors />} />
            <Route path="faq" element={<Faq />} />
            <Route path="contact" element={<Contact />} />
            <Route path="contact/confirm" element={<ContactConfirm />} />
            <Route
              path="dashboard/issuer/:issuerId"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/vendor/:vendorId"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            >
              <Route path="admin/*" element={<AdminPanel />}>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <Navigate to="users" replace={true} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="users"
                  element={
                    <ProtectedRoute>
                      <UsersList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="vendors"
                  element={
                    <ProtectedRoute>
                      <VendorsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="reporting-units"
                  element={
                    <ProtectedRoute>
                      <ReportingUnitsList />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="test" element={<Test />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AuthProvider>
    </ConfigProvider>
  )
}

export default App
